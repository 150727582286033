.App {
  text-align: center;
}

.App-header {
  background-color: rgb(0, 0, 53);
  height: 7vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 3vh;
  color: white;
}

.logo {
  /* height: 40vmin; */
  height: 4vh;
  margin-top: -3vh;
  object-fit: fill;
}


.Body {
  padding: 2vh;
  font-size: 17px;
  text-align: left;
  color: white;
  background-color: #1f1f1f;
  text-decoration: none;
  
}

.Body_No_Page {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2vh;
  min-height: 86.1vh;
  font-size: 17px;
  text-align: left;
  color: white;
  background-color: #000000;
  text-decoration: none;
}

.Personal_Photo{
  object-fit: fill;
  object-position: left;
  justify-content: left;
  align-items: left;
  width: 25vw;
  border-radius: 25px;
}

.container {
  display: flex;
  justify-content: space-between; /* Adjust as needed */
}

.item {
  padding: 10px;
  /* border: 1px solid #000; */
  text-align: left;
}
h4{
  font-weight: normal;
}
h5{
  text-indent: 1em;
  font-weight: normal;  
}
h6{
padding-left: 2em;
font-weight: normal;
}
.link{
  font-size: 17px;
  text-align: left;
  color: white;
}

.form {
  max-width: 400px;
  margin: 0 auto;
  border: red;
  border-width: 10px;
}

.div {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
}

.input, .textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}


/* NavBar.css */
.topnav {
  margin-top: -3vh;
  overflow: hidden;
  background-color: #1f1f1f;
  border-radius: 25px;
}

.topnav a {

  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
/*  rgb(62, 112, 242)*/
.topnav a:hover {
  background-color: white;
  color: black;
}

.topnav a.active {
  background-color: rgb(62, 112, 242);
  color: white;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 900px) {
  .topnav a:nth-child(n+0) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 900px) {  
   .topnav.responsive {
    position: relative;
    float: right;
    margin-top: auto;
    overflow: hidden;
  } 
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top:00;
    
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }   
}  


.footer {
  text-align: center;
  padding: 20px;
  color: white;
  background-color: rgb(0, 0, 53);
}

.footer p {
  margin: 0;
  padding: 10px 0;
}

.social-links {
  margin-top: 10px;
}

.social-links a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
}

.social-links a:hover {
  color: #007BFF;
}

.app-link {
  color: white;
  text-decoration: none;
}.app-link:hover {
  text-decoration: underline;
}
.app-text{
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  align-items: center;
}

.screenshots{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  border-radius: 25px;
}

.screenshot-image {
  max-width: 25%;
  height: auto;
  border-radius: 25px;
  flex-direction: row;
}

.project-link {
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-link:hover {
  text-decoration: underline;
  background-color: rgb(62, 112, 242);
}

.project-image {
  max-width: 100%;
  height: auto;
  border-radius: 25px;
}

.spacer {
  flex-grow: 1;
}